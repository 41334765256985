import React from 'react'
import { Helmet } from 'react-helmet'
import { HiMiniArrowLongRight } from 'react-icons/hi2'
import { Link } from 'react-router-dom'

const blog1 = `${process.env.PUBLIC_URL}/images/blog1.png`
const blog2 = `${process.env.PUBLIC_URL}/images/blog2.png`
const blog3 = `${process.env.PUBLIC_URL}/images/blog3.png`
const blog4 = `${process.env.PUBLIC_URL}/images/blog4.png`
const blog5 = `${process.env.PUBLIC_URL}/images/blog5.png`
const blog6 = `${process.env.PUBLIC_URL}/images/blog6.png`


const guide = [
    {
        id: 1,
        img: blog1,
        title: 'The real estate industry, a cornerstone of global economies',
        desc:'It is undergoing a significant transformation with the advent of blockchain technology.',
        time: '2 min read',
        views: '1209 views',
        date: '01 aug 2024',
    },
    {
        id: 2,
        img: blog2,
        title: 'High-value collectibles and vintage assets',
        desc:'the intersection of blockchain technology and real-world asset (RWA) tokenization',
        time: '5 min read',
        views: '1580 views',
        date: '22 july 2024',
    },
    {
        id: 3,
        img: blog3,
        title: 'Financial landscape Undergoing a Profound Transformation',
        desc:'driven by the advent of blockchain technology and the innovative practice of',
        time: '3 min read',
        views: '812 views',
        date: '25 july 2024',
    },
]




const Card = ({data}) => {
    return(
        <div className=' w-[357px] h-[420px] flex flex-col justify-between rounded-xl bg-white shadow-lg'>

             <img src={data.img} alt='blogs' className=' w-[357px] h-[240px] rounded-lg ' />

             <div className=' px-5 pt-2 flex w-full justify-between'>
                <span className=' pr-3 text-xs font-semibold border-r border-black'>{data.time}</span>
                <span className=' pr-8 text-xs font-semibold border-r border-black'>{data.views}</span>
                <span className=' pr-3 text-xs font-semibold'>{data.date}</span>
             </div>

            <div className=' pt-5 px-5'>
                <p className=' text-base text-neutral-700 font-bold'>{data.title}</p>
                <p className=' text-sm mt-3 text-neutral-700 font-semibold'>{data.desc}</p>
            </div>
            <Link  to={`/blogs/${data.id}`}><h1 className='flex gap-3 items-end pb-3 ml-5 mt-2 text-primary-1 font-semibold text-base'>Read Blog <span className=' hover:translate-x-1 transition-all text-xl'><HiMiniArrowLongRight /></span></h1></Link>

        </div>
    )
}

function ExploreBlogs() {
  return (
    <div className=' p-20 flex flex-col bg-white'>
              <Helmet>
                <title>Originals Gateway Blogs | Insights on Real-World Asset Tokenization & Blockchain Innovation</title>
                <meta
                  name="description"
                  content="Stay updated with the latest insights, trends, and news on real-world asset tokenization, blockchain technology, and digital asset management at the Originals Gateway blog."
                />
                <meta name="keywords" content="blockchain blog, asset tokenization, digital asset management, real-world assets, blockchain technology insights, asset digitization news, tokenization trends" />
              </Helmet>
        <h1 className=' text-2xl text-primary-1 font-bold'>Home/blog</h1>
        <div className=' my-10 flex lg:flex-row flex-col gap-6'>
            <img src='/images/blogBanner.png' className=' w-1/2' alt='blogs' />
            <div className=' w-1/2 p-10 flex flex-col gap-8'>
                <h1 className='text-4xl text-neutral-600 text-wrap font-bold'>Welcome to the “Real World Assets” Blogs</h1>
                <p className=' text-xl font-bold text-neutral-500'>The real estate industry, a cornerstone of global economies, is undergoing a significant transformation with the advent of blockchain technology. One of the most promising innovations is the tokenization of real-world assets (RWAs).</p>
                <p className=' text-xl font-bold text-neutral-500'>Tokenization involves creating a digital representation of a real-world asset on a blockchain. For real estate, this means that properties can be divided into smaller, fractional shares, each represented by a token. </p>
                <Link to='/blogs/blogs/1'> <button className=' mt-10 px-6 py-2 bg-primary-1 text-white font-semibold rounded-md self-end'> Read More</button> </Link>
            </div>
        </div>

{/* ---------guides ----------- */}
        <div className=' flex flex-col gap-6 my-10'>
            <div className=' flex justify-between items-end'>
                <h1 className=' text-3xl text-neutral-700 font-bold'>Originals Gateway Blogs</h1>
                <Link to='/blogs'> <button className=' mt-10 px-6 py-2 bg-primary-1 text-white font-semibold rounded-md self-end'> Explore More</button> </Link>
            </div>

            <div className=' mt-10 flex flex-wrap w-full justify-between'>
                    {guide.map((d,i) => (
                        <Card data={d} />
                    ))}
                </div>
        </div>
    </div>
  )
}

export default ExploreBlogs