import { HeroSection } from "./heroSection";
import Carousel from "./carousel";
import RealWord from "./RealWorld";
import Tokenization from "./Tokenisation";
import KeyFeatures from "./KeyFeatures";
import How from "./WorkCard";
import Testimonials from "./Testimonials";
import Partners from "./Partners";
import Blogs from "./Blogs";
import GlobeUi from "./globe";
import { Helmet } from "react-helmet";

import "./home.css";
import FaqComponent from "../faqPage/FaqComponent";
export const HomePage = () => {
  return (
    <div className="bg-white overflow-x-hidden">
      <Helmet>
        <title>Originals Gateway | Tokenizing Real-World Assets </title>
        <meta
          name="description"
          content="Discover how Originals Gateway revolutionizes real-world asset tokenization, offering secure and efficient solutions for digitizing tangible assets. "
        />
        <meta
          name="keywords"
          content="real-world asset tokenization
digital asset tokenization,
tokenized real-world assets,
asset-backed tokens,
blockchain-based asset tokenization,
tokenized investment opportunities,
asset digitization solutions,
secure asset management blockchain,
innovative asset tokenization,
decentralized asset management,
tokenized real estate assets,
asset fractionalization,
real-world asset tokenization,
digital asset management,
blockchain for asset tokenization,
secure tokenized assets,
investment in tokenized assets,
financial innovation,
Originals Gateway
"
        />
         <meta property="og:description" content="Discover how Originals Gateway revolutionizes real-world asset tokenization, offering secure and efficient solutions for digitizing tangible assets. " />
         <meta property="og:title" content="Originals Gateway | Tokenizing Real-World Assets" />
      </Helmet>
      <HeroSection />
      {/* <GlobeUi /> */}
      <RealWord />
      <Tokenization />
      <How />
      <KeyFeatures />
      <Carousel />
      <br />
      <br />
      <Testimonials />

      <Blogs />
      <FaqComponent />
    </div>
  );
};
