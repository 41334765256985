import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

function Signup() {
  let navigate = useNavigate();
  const base_url = process.env.REACT_APP_BASE_URL;
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("User");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChangeFullName = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setEmailError("");
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setPasswordError("");
  };

  const onChangeRole = (e) => {
    const selectedRole = e.target.value;
    setUserType(selectedRole);
  };

  const validateForm = () => {
    let valid = true;

    if (fullName.trim() === "") {
      setMessage("Full Name cannot be blank");
      valid = false;
    }

    if (email.trim() === "") {
      setEmailError("Email cannot be blank");
      valid = false;
    }

    if (password.trim() === "") {
      setPasswordError("Password cannot be blank");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      axios
        .post(base_url + "/v0/user/sign-up", {
          fullName,
          email,
          password,
          userType, // Include the userType in the signup request
        })
        .then((response) => {
          setLoading(false);
          const success = response.data.success;
          const message = response.data.message;
          if (success) {
            const token = response.data.responseData.token;
            localStorage.setItem("token", token);
            localStorage.setItem("role", response.data.responseData.role);
            toast("Sign up successful !!");
            navigate("/login");
          } else {
            console.error("Registration failed:", message || "Unknown error");
            setMessage(message || "Unknown error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Registration error:", error);
          setMessage("Failed to register. Please try again later.");
        });
    }
  };

  const SignupImage = `${process.env.PUBLIC_URL}/images/signupImage.png`;

  return (
    <>
      <div className="flex min-h-screen">
      <Helmet>
        <title>Sign Up | Originals Gateway</title>
        <meta
          name="description"
          content="Sign up your account on Originals Gateway."
        />
        <meta property="og:description" content="Sign up your account on Originals Gateway." />
        <meta property="og:title" content="Sign Up | Originals Gateway" />
      </Helmet>
        <div className="flex w-full flex-col md:flex-row">
          {/* Image */}

          {/* signup Section */}
          <div className="flex flex-col md:flex-1 items-center justify-center ">
            <div className="loginWrapper flex flex-col lg:px-36 md:px-8 px-8 md:py-8 border-2 border-[#04A287] rounded-3xl bg-white">
              {/* signup Header Text */}
              <div className="items-center text-center flex flex-col relative justify-center mx-auto mb-10">
                <img
                  src="/originals-logo.svg"
                  alt="Logo Login"
                  className="md:w-80 w-64 mx-auto"
                />
              </div>
              <div className="hidden md:block font-medium self-center text-xl sm:text-3xl text-gray-800">
                Signup
              </div>

              <div className="md:hidden block my-4">
                <h1 className="text-2xl font-semibold">Signup</h1>
              </div>

              {/* Signup Form */}
              <div className="md:mt-10 mt-4">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <span className="text-sm">Full Name</span>
                      <input
                        id="fullName"
                        type="text"
                        name="fullName"
                        value={fullName}
                        onChange={onChangeFullName}
                        className="text-sm placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Enter Your Full Name"
                      />
                    </div>
                    {message && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {message}
                      </span>
                    )}
                  </div>
                  {/* Email */}
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <span className="text-sm">Email ID</span>
                      <input
                        id="email"
                        type="text"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        className="text-sm placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Enter Your Email ID"
                      />
                    </div>
                    {emailError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {emailError}
                      </span>
                    )}
                  </div>
                  {/* Password */}
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <span className="text-sm">Password</span>
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        className="text-sm placeholder-gray-500 pl-2 pr-10 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Enter Your Password"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-0 top-0 mt-2 mr-2 focus:outline-none"
                      ></button>
                    </div>
                    {passwordError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {passwordError}
                      </span>
                    )}
                  </div>

                  {/* Role Selection */}
                  <div className="flex flex-col mb-3 pb-5">
                    <div className="relative">
                      <span className="text-sm">Select Role</span>
                      <select
                        id="role"
                        name="role"
                        value={userType}
                        onChange={onChangeRole}
                        className="text-sm placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                      >
                        <option value="User">Investor</option>
                        <option value="Creator">Creator</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex items-center mb-6 -mt-2 md:-mt-4">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-emerald-500 focus:ring-emerald-400 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 text-xs text-gray-900"
                      >
                        I have read and accepted the <br />
                        <a
                          href="/terms-and-conditions"
                          className="text-emerald-500 hover:text-emerald-700 me-1"
                        >
                          Terms and Conditions
                        </a>
                        and
                        <a
                          href="/privacy-policy"
                          className="text-emerald-500 hover:text-emerald-700"
                        >
                          {" "}
                          Privacy Policy
                        </a>
                        .
                      </label>
                    </div>
                  </div>

                  {/* Button Signup */}
                  <div className="flex w-full justify-center">
                    <button
                      disabled={loading}
                      type="submit"
                      className="flex items-center justify-center focus:outline-none text-white text-sm bg-[#04A287] rounded-full md:py-2 py-3 w-1/2 transition duration-150 ease-in"
                    >
                      <span className="mr-2 md:uppercase">
                        {loading ? "Processing...." : "Signup"}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="md:bg-[#04A287] md:min-h-screen flex flex-wrap md:w-1/2">
            <div className="items-center text-center flex flex-col relative justify-center mx-auto">
              <img
                src={SignupImage}
                alt="Logo Login"
                className="md:w-96 w-48 mx-auto"
              />
              <div className="mt-6 text-white text-lg md:text-xl font-semibold relative">
                <span className="relative z-10">
                  You will get <span className="text-yellow-400">50 USDTs</span>
                  , <span className="text-green-400">50 OGs</span>, and{" "}
                  <span className="text-blue-400">10,000 Demo USD</span> as
                  Signup bonus.
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 opacity-50 rounded-lg blur-lg animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
