import { useContext, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { WalletInfoContext } from "../../hooks/useWalletInfo";
import { Helmet } from "react-helmet";

export const Trade = () => {
  const [formType, setFormType] = useState("buy");
  const [og, setOg] = useState(0);
  const [USDValue, setUSDValue] = useState(0);
  const [ogBalance, setOgBalance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { walletInfo } = useContext(WalletInfoContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const valueToCheck = formType === "buy" ? USDValue : og;

      if (valueToCheck === 0 || valueToCheck === "" || valueToCheck === "0" ) {
        toast.error("Please provide a value greater than 0.");
        setIsLoading(false);
        return;
      }

      if (formType === "buy") {
        if (USDValue > usdtBalance) {
          toast.error("Insufficient USDT balance");
          setIsLoading(false);
          return;
        }
        await buyTOKENWithAPI(USDValue);
      } else {
        if (og > ogBalance) {
          toast.error("Insufficient OG balance");
          setIsLoading(false);
          return;
        }
        await sellTOKENWithAPI(og);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Error during API call:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTransactionLink = (transactionHash) => {
    return `https://amoy.polygonscan.com/tx/${transactionHash}`;
  };
  useEffect(() => {
    if (walletInfo && walletInfo.walletAddress) {
      fetchBalance("og-balance", setOgBalance);
      fetchBalance("usdt-balance", setUsdtBalance);
    }
  }, [walletInfo]);

  const fetchBalance = async (endpoint, setBalance) => {
    if (!walletInfo || !walletInfo.walletAddress) {
      console.error("Wallet address is not available.");
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/v0/user/${endpoint}`,
        new URLSearchParams({ address: walletInfo.walletAddress }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setBalance(parseFloat(response.data.responseData).toFixed(2) || "0");
    } catch (error) {
      console.error(`Error fetching ${endpoint} balance:`, error);
    }
  };
  const buyTOKENWithAPI = async (usdtAmount) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/v0/user/buy-og`,
        { usdtAmount },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.message === "Successfull") {
        const transactionHash = response.data.responseData.transactionHash;
        toast.custom(
          <div
            style={{
              padding: "10px",
              backgroundColor: "#1a202c",
              color: "#fff",
              borderRadius: "5px",
              fontSize: "16px",
            }}
          >
            Transaction Successful!{" "}
            <a
              href={getTransactionLink(transactionHash)}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#63b3ed",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              View Transaction
            </a>
          </div>
        );
        console.log("API Response:", response.data);
      } else {
        toast.error(`Transaction Failed!`);
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const sellTOKENWithAPI = async (ogAmount) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/v0/user/sell-og`,
        { ogAmount },
        {
          headers: {
            token: token,
          },
        }
      );

      if (response.data.message === "Successfull") {
        const transactionHash = response.data.responseData.transactionHash;
        toast.custom(
          <div
            style={{
              padding: "10px",
              backgroundColor: "#1a202c",
              color: "#fff",
              borderRadius: "5px",
              fontSize: "16px",
            }}
          >
            Transaction Successful!{" "}
            <a
              href={getTransactionLink(transactionHash)}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#63b3ed",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              View Transaction
            </a>
          </div>
        );
        console.log("API Response:", response.data);
      } else {
        toast.error(`Transaction Failed!`);
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const handleOgChange = (value) => {
    const inputValue = value.replace(/[^0-9.]/g, "");
    if (!isNaN(inputValue)) {
      setOg(inputValue);
      setUSDValue(inputValue);
    }
  };

  return (
    <div className="container">
       <Helmet>
  <title>Buy and Sell OG Tokens | Originals Gateway</title>
  <meta
    name="description"
    content="Trade OG tokens on Originals Gateway."
  />
</Helmet>

      <div className="py-16">
        <div className="max-w-md mx-auto bg-white p-8 rounded shadow-md">
          <div className="mb-4 w-full flex">
            <button
              onClick={() => setFormType("buy")}
              className={`mr-4 md:w-1/2 px-4 py-2 rounded ${
                formType === "buy"
                  ? "bg-green-500 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              Buy
            </button>
            <button
              onClick={() => setFormType("sell")}
              className={`px-4 md:w-1/2 py-2 rounded ${
                formType === "sell"
                  ? "bg-red-500 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              Sell
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="input1"
                className="block text-gray-700 font-bold mb-2"
              >
                Token
              </label>
              <input
                type="text"
                id="input1"
                value={og}
                onChange={(e) => handleOgChange(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Enter Token Value"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="input2"
                className="block text-gray-700 font-bold mb-2"
              >
                USDT
              </label>
              <input
                type="text"
                id="input2"
                value={USDValue}
                disabled
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="USDT Value"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  isLoading || USDValue === 0 || USDValue === "" || og === 0
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  isLoading || USDValue === 0 || USDValue === "" || og === 0
                }
              >
                {isLoading ? "Processing..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
