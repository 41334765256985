import React from 'react'

import { useEffect, useState } from "react";
import { get } from "../../Service/Apiservice";
import { config } from "../../config";
import { Link } from 'react-router-dom';
import { RiNftFill } from "react-icons/ri";
import CollectionSkeleton from '../../componets/loader/CollectionSkeleton';
import FilterSection from './components/FilterSection';
import './collection.css'
import { IoHeartCircleOutline } from 'react-icons/io5';
import { Helmet } from 'react-helmet';

function CollectionPage() {
    const { getprojects } = config;
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialLoading, setIsInitialLoading] = useState(true);


    useEffect(() => {
        const fetchProject = async () => {
        try {
            const response = await get(getprojects, {
            headers: {
                "Content-Type": "application/json",
            },
            });
            console.log(response);
            setData(response.responseData[0]?.data);
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }finally {
            setIsInitialLoading(false);
          }
        };
        fetchProject();
    }, []);

    


  return (

    <div className=' w-full h-full  flex gap-5 '>
        <Helmet>
        <title>Buy and Sell NFTs | Originals Gateway</title>
<meta
  name="description"
  content="Explore and trade a diverse collection of NFTs on Originals Gateway. Buy, sell, and discover unique digital assets securely."
/>

       
      </Helmet>
         {isInitialLoading ? (
        <div className="flex w-full items-center justify-center h-screen bg-white">
          <div class="loader"></div>
        </div>
      ) : (
        <>
        <div className=' sticky top-16 w-1/2  md:w-1/3 lg:w-1/5 overflow-y-auto scrollbar-track-slate-200 scrollbar-thumb-[#04a287] scrollbar-thin h-[92vh] p-3  flex flex-col bg-white rounded-md'>
           <FilterSection />
        </div>
       
    <div className=' relative collection md:p-5  flex flex-col  w-1/2 md:w-2/3 lg:w-4/5 gap-12'>
        <h1 className=' text-4xl text-center font-semibold text-[#04a287] self-center'>Current Collections</h1>

        <div className=' flex flex-wrap justify-center gap-x-12 gap-y-16'>

            
            

        {Data.length === 0 && !isLoading ? (
          <div className='text-xl font-semibold text-white'>
            No collections available
          </div>
        ) : (
            Data.map((data, i) => (

                <div className=' flex flex-col w-[300px]   border rounded-xl shadow-[5px_5px_rgba(0,_98,_90,_0.4),_10px_10px_rgba(0,_98,_90,_0.3),_15px_15px_rgba(0,_98,_90,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(0,_98,_90,_0.05)]  bg-white  bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20'>

                  <div className=' flex w-full justify-between p-3'>
                  <h1 className=' text-xl text-neutral-700  font-bold capitalize'>{data?.displayName}</h1>

                  <div className=' bg-white h-fit rounded-lg shadow-md p-1 items-center flex gap-1'>
                    <IoHeartCircleOutline className=' cursor-pointer text-xl text-pink-300' />
                    <span className='text-sm'>0</span>
                  </div> 
                    
                    </div>  
                    
                    <div className='w-full h-[230px] mb-4 overflow-hidden px-3 '>
                        <img src={data?.collectionImage} className='w-full h-full object-cover ' />
                    </div>
                    
                        

                    <Link key={data?._id} to={`/investments/${data._id}`}> <div className=' w-full flex items-center justify-center gap-2 self-end rounded-lg bg-primary-1 p-1 font-semibold text-lg text-white'><span className='text-sm rounded-full  px-1.5  bg-white text-[#04a287] '>{data?.placeNftCount}</span>  NFTS <RiNftFill /></div>
                        
                  
                   
                    </Link>
                </div>

            ))

            )}

        </div>
    </div>
    </>
      )}
</div>
  )
}

export default CollectionPage